const personalityTypes: { id: string; text: string }[] = [
  { id: "0", text: "Bubbly and outgoing" },
  { id: "1", text: "Calm and reflective" },
  { id: "2", text: "Mature and experienced" },
  { id: "3", text: "Youthful and relatable" },
  {
    id: "4",
    text: "Pro-active and motivational",
  },
  { id: "5", text: "Logical and direct" },
  { id: "6", text: "Functional and empowering" },
];

export const speechPersonalityTypes: { id: string; text: string }[] = [
  { id: "0", text: "Bubbly and outgoing" },
  { id: "1", text: "Calm and reflective" },
  { id: "2", text: "Mature and experienced" },
  { id: "3", text: "Youthful and relatable" },
  {
    id: "4",
    text: "Pro-active and motivational",
  },
  { id: "5", text: "Logical and direct" },
  { id: "6", text: "Functional and empowering" },
];

export const counsellingPersonalityTypes: { id: string; text: string }[] = [
  { id: "0", text: "Bubbly and outgoing" },
  { id: "1", text: "Calm and reflective" },
  { id: "2", text: "Mature and experienced" },
  { id: "3", text: "Youthful and relatable" },
  {
    id: "4",
    text: "Pro-active and motivational",
  },
  { id: "5", text: "Logical and direct" },
];

export default personalityTypes;
