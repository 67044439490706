import personalityTypes from "@/assets/PersonalityTypes";

interface Filters {
  [key: string]: any;
  specialisms?: Set<number>;
  dayOfWeek?: Set<string>;
  timeOfDay?: Set<string>;
  personalityTypes?: string[];
}

interface SerializedFilters {
  [key: string]: any;
  specialisms?: number[];
  dayOfWeek?: string[];
  timeOfDay?: string[];
  personalityTypes?: string[];
}

interface StoredFilters {
  "child-counselling": SerializedFilters;
  "speech-therapy": SerializedFilters;
}

export function isSet(value: any): value is Set<any> {
  return value instanceof Set;
}

export function serializeFilters(filters: Filters): SerializedFilters {
  const serialized: SerializedFilters = {};

  Object.entries(filters).forEach(([key, value]) => {
    if (isSet(value)) {
      serialized[key] = Array.from(value);
    } else if (value !== undefined) {
      serialized[key] = value;
    }
  });

  return serialized;
}

export function deserializeFilters(serialized: SerializedFilters): Filters {
  const deserialized: Filters = {};

  Object.entries(serialized).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      switch (key) {
        case "specialisms":
        case "dayOfWeek":
        case "timeOfDay":
          deserialized[key] = new Set(value);
          break;
        default:
          deserialized[key] = value;
      }
    } else {
      deserialized[key] = value;
    }
  });

  return deserialized;
}

function getCurrentPath(): "child-counselling" | "speech-therapy" {
  const path = window.location.pathname;
  return path.includes("speech-therapy")
    ? "speech-therapy"
    : "child-counselling";
}

export function saveFiltersToStorage(filters: Filters): void {
  const currentPath = getCurrentPath();
  const allStoredFilters: StoredFilters = JSON.parse(
    localStorage.getItem("pathFilters") ||
      '{"child-counselling":{},"speech-therapy":{}}'
  );

  allStoredFilters[currentPath] = serializeFilters(filters);

  localStorage.setItem("pathFilters", JSON.stringify(allStoredFilters));
  localStorage.setItem(
    `filtersLastUpdated_${currentPath}`,
    new Date().toISOString()
  );
}

export function loadFiltersFromStorage(): Filters | null {
  const currentPath = getCurrentPath();
  const allStoredFilters = localStorage.getItem("pathFilters");

  if (!allStoredFilters) return null;

  try {
    const parsed = JSON.parse(allStoredFilters) as StoredFilters;
    const pathFilters = parsed[currentPath];

    if (!pathFilters || Object.keys(pathFilters).length === 0) return null;

    // Check if filters are expired (older than 1 hour)
    const lastUpdated = localStorage.getItem(
      `filtersLastUpdated_${currentPath}`
    );
    if (lastUpdated) {
      const timeElapsed = Date.now() - new Date(lastUpdated).getTime();
      const oneHour = 60 * 60 * 1000;
      if (timeElapsed > oneHour) {
        clearStoredFilters();
        return null;
      }
    }

    return deserializeFilters(pathFilters);
  } catch (error) {
    console.error("Error loading filters from storage:", error);
    return null;
  }
}

export const convertAppliedFiltersToQueryParamStrings = () => {
  const filters = loadFiltersFromStorage();
  if (!filters || Object.keys(filters).length === 0) {
    return "";
  }
  const params = new URLSearchParams();
  const serialized = serializeFilters(filters);

  // Map storage keys to URL query parameter names
  const keyMapping: Record<string, string> = {
    childsAge: "patientAge",
    dayOfWeek: "days",
    timeOfDay: "times",
    keywords: "personalityTypes",
  };

  Object.entries(serialized).forEach(([key, value]) => {
    if (value && typeof value === "object" && Object.keys(value).length === 0) {
      return; // Skip empty objects
    }
    const paramKey = keyMapping[key] || key;

    if (key === "keywords") {
      let keywordsArr: string[] = [];
      if (Array.isArray(value)) {
        keywordsArr = value;
      } else if (typeof value === "string") {
        keywordsArr = value.split(",").map((v) => v.trim());
      }
      if (keywordsArr.length > 0) {
        // Map each keyword to its personality type id; filter out those not found.
        const converted = keywordsArr
          .map((word) => {
            const found = personalityTypes.find(
              (pt) => pt.text.toLowerCase() === word.toLowerCase()
            );
            return found ? found.id : null;
          })
          .filter((id): id is string => id !== null);
        if (converted.length > 0) {
          params.set(paramKey, converted.join(","));
        }
      }
    } else if (Array.isArray(value)) {
      if (value.length > 0) {
        params.set(paramKey, value.join(","));
      }
    } else if (value !== undefined && value !== null) {
      params.set(paramKey, value.toString());
    }
  });
  return params.toString();
};

export function clearStoredFilters(): void {
  const currentPath = getCurrentPath();
  const allStoredFilters: StoredFilters = JSON.parse(
    localStorage.getItem("pathFilters") ||
      '{"child-counselling":{},"speech-therapy":{}}'
  );

  allStoredFilters[currentPath] = {};

  localStorage.setItem("pathFilters", JSON.stringify(allStoredFilters));
  localStorage.removeItem(`filtersLastUpdated_${currentPath}`);
}
